<template>
  <b-modal
    id="project-user-modal"
    ref="project-user-modal"
    :title="$t('ADD_PROJECT_MEMBER')"
    :ok-title="projectUser.id ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="validationRules">
      <b-form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('EMAIL_ADDRESS')"
              label-for="id-email"
              :invalid-feedback="$t('DESCRIPTION_REQUIRED')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('EMAIL_ADDRESS')"
                rules="required"
              >
                <b-form-input
                  id="id-email"
                  v-model="projectUser.email"
                  maxlength="60"
                  :placeholder="$t('EMAIL_ADDRESS')"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- First Name -->
          <b-col md="6">
            <b-form-group
              :label="$t('FIRST_NAME')"
              label-for="id-firstname"
            >
              <b-form-input
                id="id-firstname"
                v-model="projectUser.firstname"
                maxlength="45"
                :placeholder="$t('FIRST_NAME')"
              />
            </b-form-group>
          </b-col>
          <!-- Last Name -->
          <b-col md="6">
            <b-form-group
              :label="$t('LAST_NAME')"
              label-for="id-lastname"
            >

              <b-form-input
                id="id-lastname"
                v-model="projectUser.lastname"
                maxlength="45"
                :placeholder="$t('LAST_NAME')"
              />

            </b-form-group>
          </b-col>
          <!-- Business Unit-->
          <b-col md="6">
            <b-form-group
              :label="$t('BUSINESS_UNIT')"
              label-for="id-business-unit"
            >
              <b-form-input
                id="id-business-unit"
                v-model="projectUser.business_unit"
                maxlength="50"
                :placeholder="$t('BUSINESS_UNIT')"
              />
            </b-form-group>
          </b-col>
          <!-- Project Role -->
          <b-col md="6">
            <b-form-group
              :label="$t('ROLE')"
              label-for="id-role"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('ROLE')"
                rules="required"
              >
                <b-form-input
                  id="id-role"
                  v-model="projectUser.role"
                  maxlength="45"
                  :placeholder="$t('ROLE')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <b-form-checkbox
                id="checkbox-is_project_admin"
                v-model="projectUser.is_project_admin"
                :true-value="true"
                :false-value="false"
                name="checkbox-is_project_admin"
              >
                {{ $t('PROJECT_ADMIN') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <!-- Phone Number -->
          <b-col md="12">
            <b-form-group
              :label="$t('PHONE_NUMBER')"
              label-for="id-phonenumber"
            >
              <VuePhoneNumberInput
                v-model="projectUser.phonenumber"
                :default-country-code="getIsoCountryCode(projectUser.country_code+projectUser.phonenumber)"
                @update="handlePhoneNumberInput"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal,
  VBModal, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuePhoneNumberInput from 'vue-phone-number-input'
// eslint-disable-next-line import/no-extraneous-dependencies
import parsePhoneNumberFromString from 'libphonenumber-js'
import { mapActions } from 'vuex'
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    VuePhoneNumberInput,
    // eslint-disable-next-line vue/no-unused-components
    parsePhoneNumberFromString,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'editProjectUser',
  },
  props: {
    editProjectUser: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    projectUserSave: {
      type: Function,
      required: true,
    },
    projectUserEdit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      projectUser: {
        id: 0,
        project_id: 0,
        user_id: '',
        role: '',
        userRoleId: 0,
        is_project_admin: false,
        firstname: '',
        lastname: '',
        email: '',
        companyID: 0,
        phonenumber: '',
        business_unit: '',
        country_code: constants.DEFULT_COUNTRY_CODE.DIALING_CODE,
      },
      phonePayload: {
        countryCode: '',
        isValid: false,
        phoneNumber: '',
        countryCallingCode: constants.DEFULT_COUNTRY_CODE.ISO_COUNTRY_CODE,
        formattedNumber: '',
        nationalNumber: '',
        formatInternational: '',
        formatNational: '',
        uri: '',
        e164: '',
      },
      required,

    }
  },
  watch: {
    company: {
      handler() {
        if (this.company) {
          this.projectUser.business_unit = this.company.companyname
        }
      },
    },
    editProjectUser: {
      handler() {
        if (this.editProjectUser) {
          this.projectUser = { ...this.editProjectUser }
          this.projectUser.firstname = this.editProjectUser.user.firstname
          this.projectUser.lastname = this.editProjectUser.user.lastname
          this.projectUser.email = this.editProjectUser.user.email
          this.projectUser.phonenumber = this.editProjectUser.user.phonenumber
          this.projectUser.business_unit = this.editProjectUser.user.business_unit
          this.projectUser.userRoleId = this.editProjectUser.user.userRoleId
          this.projectUser.is_project_admin = !!this.editProjectUser.is_project_admin
          this.projectUser.role = this.editProjectUser.role
          delete this.projectUser.user
        }
      },
    },
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('user', ['createUser', 'updateUser']),
    ...mapActions('onboarding', ['fetchUserByEmail']),
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handlePhoneNumberInput(payload) {
      // Access the detailed payload from the @input event
      this.phonePayload = payload
    },
    getIsoCountryCode(mobile) {
      let isoCountryCode = constants.DEFULT_COUNTRY_CODE.ISO_COUNTRY_CODE
      if (mobile) {
        const phoneNumber = parsePhoneNumberFromString(mobile, 'ZZ')
        // Extract the ISO country code
        isoCountryCode = phoneNumber ? phoneNumber.country : constants.DEFULT_COUNTRY_CODE.ISO_COUNTRY_CODE
      }
      return isoCountryCode
    },
    resetModal() {
      this.projectUser = {
        id: 0,
        project_id: 0,
        user_id: '',
        role: '',
        is_project_admin: false,
        firstname: '',
        lastname: '',
        email: '',
        companyID: 0,
        phonenumber: '',
        business_unit: '',
      }
    },
    handleSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.projectUser.companyID = this.company.companyID
          if (this.phonePayload.nationalNumber) {
            this.projectUser.phonenumber = this.phonePayload.nationalNumber
            this.projectUser.country_code = `+${this.phonePayload.countryCallingCode}`
          }
          if (this.projectUser.user_id) {
            this.projectUser.userID = this.projectUser.user_id
            this.updateUser(this.projectUser).then(response => {
              if (response) {
                this.projectUserEdit(this.projectUser)
                this.successMessage(this.$i18n.t('MESSAGE.USER_UPDATED'))
                this.$bvModal.hide('project-user-modal')
                this.resetModal()
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          } else {
            this.fetchUserByEmail(this.projectUser.email).then(response => {
              if (response) {
                if (response.isExists) {
                  this.errorMessage(this.$i18n.t('ALREADY_EXIST_EMAIL'))
                } else {
                  this.createUser(this.projectUser).then(result => {
                    if (result) {
                      // eslint-disable-next-line no-param-reassign
                      result.data.is_project_admin = this.projectUser.is_project_admin
                      this.projectUserSave(result.data)
                      this.$bvModal.hide('project-user-modal')
                      this.resetModal()
                      this.successMessage(this.$i18n.t('MESSAGE.USER_CREATED'))
                    }
                  }).catch(() => {
                    this.showErrorMessage()
                  })
                }
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~vue-phone-number-input/dist/vue-phone-number-input.css';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
